import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from '@/views/Homepage.vue'
import login from '../views/Login.vue'
import forgotPassword from '@/views/forgotPassword.vue'
import forgotPasswordSuccess from '@/views/forgotPasswordSuccess.vue'


Vue.use(VueRouter)

const routes = [{

        path: '/',
        name: 'login',
        component: login
    },
    { path: '/forgotpassword', name: 'forgot', component: forgotPassword },
    { path: '/forgotPasswordSuccess', name: 'forgotSuccess', component: forgotPasswordSuccess },
    {
        path: '/Home',
        component: Homepage,
        children: [{
                path: '/ManageCategory',
                name: 'ManageCategory',
                component: () =>
                    import ('@/views/ManageCategory')
            },
            {
                path: '/CreateCategory',
                name: 'CreateCategory',
                component: () =>
                    import ('@/components/Category/CreateCategory.vue')
            },
            {
                path: '/EditCategory',
                name: 'EditCategory',
                component: () =>
                    import ('@/components/Category/EditCategory.vue')
            },
            {
                path: '/ManageType',
                name: 'ManageType',
                component: () =>
                    import ('@/views/ManageType')
            },
            {
                path: '/CreateType',
                name: 'CreateType',
                component: () =>
                    import ('@/components/Type/CreateType.vue')
            },
            {
                path: '/EditType',
                name: 'EditType',
                component: () =>
                    import ('@/components/Type/EditType.vue')
            },
            {
                path: '/CreateProduct',
                name: 'CreateProduct',
                component: () =>
                    import ('@/components/Product/CreateProduct.vue')
            },
            {
                path: '/ManageProduct',
                name: 'ManageProduct',
                component: () =>
                    import ('@/views/ManageProduct')
            },
            {
                path: '/CreateProduct',
                name: 'CreateProduct',
                component: () =>
                    import ('@/components/Product/CreateProduct.vue')
            },
            {
                path: '/viewProduct',
                name: 'viewProduct',
                component: () =>
                    import ('@/components/Product/viewProduct.vue')
            },
            {
                path: '/EditProduct',
                name: 'EditProduct',
                component: () =>
                    import ('@/components/Product/EditProduct.vue')
            },
            {
                path: '/ManageUser',
                name: 'ManageUser',
                component: () =>
                    import ('@/views/ManageUser')
            },
            {
                path: '/viewUser',
                name: 'viewUser',
                component: () =>
                    import ('@/components/User/viewUser.vue')
            }, {
                path: '/ManageOrder',
                name: 'ManageOrder',
                component: () =>
                    import ('@/views/ManageOrder')
            },
            {
                path: '/viewOrder',
                name: 'viewOrder',
                component: () =>
                    import ('@/components/Order/viewOrder.vue')
            },
            {
                path: '/EditOrder',
                name: 'EditOrder',
                component: () =>
                    import ('@/components/Order/EditOrder.vue')
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router